import React,{ useState } from 'react';
import {Link} from 'react-router-dom';
// import DisplayModel from '../../../../component/DisplayModel';
import LinkIcon from '../../../../assets/Link_icon.svg';
import Modal from 'react-bootstrap/Modal';
import BaseURL from '../../../../baseURL';
import axios from 'axios';

function TableReports(props) {
    const [show, setShow] = useState(false);
    // const [comment,setComment]=useState('');
    const [data,setData]=useState({
        comment:null,
        id:null
    })

    const TaskComment=(id,comments)=>{
        setShow(true);
        setData({
            id: id,
            comment: comments
        });
    }

    const commentHandler=(e)=>{
       e.preventDefault();
        axios.post(`${BaseURL}/task_comment.php`,data)
        .then((res)=>{
            if(res.data.status==='success'){
                setShow(false);
                props.DataRefresh();
            }

        })
        .catch((err)=>{
            console.log(err);
        })
    }
    
    // const [ModelCommentBody, setModelCommentBody] = useState('')
    const reports_data=props.Reports;
    const tasks_data=props.Tasks;
   
    const isBudgetEmpty =  tasks_data && tasks_data.length > 0 ? tasks_data.every(item => !item.budget) :false;
    const isResults =  tasks_data && tasks_data.length > 0 ? tasks_data.every(item => !item.results) :false;
    const isComments =  tasks_data && tasks_data.length > 0 ? tasks_data.every(item => !item.comments) :false;
    return (
        <>
            {/* <h5>Tasks</h5>
            <table className='tab_tables fs14 my-3'>
               <thead>
                    <tr>
                        <th style={{width: isBudgetEmpty ? '500px' :'200px'}}>Date</th>
                        <th >Particulars</th>
                        {!isBudgetEmpty &&<th>Budget</th>}
                        {!isResults &&<th>Results</th>}
                        {!isComments &&<th>Comments</th>}
                        
                    </tr>
                </thead>
                <tbody>
                {
                tasks_data && tasks_data.length > 0 ? (
                    tasks_data.map((item, index) => (
                        <tr className='data_tr' key={index} onClick={!isComments ? ()=>TaskComment(item.id,item.comments) :null}>
                            <td >
                               {item.date}
                            </td>
                            <td><div dangerouslySetInnerHTML={{ __html: item.task }}></div></td>
                            {!isBudgetEmpty &&<td>{item.budget}</td>}
                            {!isResults &&<td>{item.results}</td>}
                            {!isComments &&<td>{item.comments}</td>}
                           
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="5" style={{textAlign: 'center'}}>No data available</td>
                    </tr>
                )
                }
                </tbody>
            </table> */}

            <h5 className='mt-5'>Monthly Reports</h5>
            <table className='tab_tables fs14'>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Submitted on</th>
                        <th>Link</th>
                    </tr>
                </thead>
                <tbody>
                {
                    reports_data && reports_data.length > 0 ? (
                        reports_data.map((item, index) => (
                            <tr className='data_tr' key={index}>
                                <td>{item.title}</td>
                                <td style={{width:'150px'}}>{item.submitted_on}</td>
                                <td style={{width:'50px'}}>
                                    <Link to={item.link} target='_blank'>
                                        <img src={LinkIcon} style={{width:30}} alt="" />
                                    </Link>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" style={{textAlign: 'center'}}>No data available</td>
                        </tr>
                    )
                }
                </tbody>
            </table>


            {/* <DisplayModel id='display_model_task' title='Task Details' body={ModelCommentBody}/> */}

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className=" fs-5 maven_pro_medium">Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={commentHandler}>
                        <label className='mb-2 maven_pro_regular'>Comments</label>
                        <textarea className='form-control mb-4 maven_pro_regular' required value={data.comment} onChange={(e)=>setData({...data,comment:e.target.value})}></textarea>
                        <button className='btn btn-yellow w-100 maven_pro_semibold'>Update</button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TableReports